import React, { FC, useState } from "react";
import {
  alpha,
  Container,
  Fade,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Theme,
  withStyles
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { NavLinkDropdownMobile, PageLink } from "components/common/atoms";
import { BurgerMenu, BurgerMenuDark, DarkMobileLogo, NavCloseIcon, NavCloseIconDark } from "assets/svgs/nav";
import { useIsDarkTheme } from "hooks";
import { LogoLight } from "assets/images/inline-svg";
import { NewNavInterface } from "containers/common/sections/layout/Nav/Nav";
import clsx from "clsx";
import MobileAppLink from "containers/common/sections/MobileAppLink";
import SubNavMobile from "containers/common/sections/layout/Nav/SubNavMobile";
import useI18n from "evcharging/hooks/useI18n";
import { dropdownOptions } from "evcharging/providers/ServicesProvider";

const useStyles = makeStyles((theme: Theme) => ({
  topNav: {
    height: 68,
    backgroundColor: '#333',
    boxShadow: 'none',
    width: '100%',
  },
  newTopNavTransparent: {
    backgroundColor: 'transparent',
  },
  newShadowTopNav: {
    backgroundColor: `${alpha(theme.palette.common.black, 0.3)}`,
  },
  blockIcon: {
    marginRight: theme.spacing(-2),
    [theme.breakpoints.down('md')]: {
      marginRight: theme.spacing(1),
    },
  },
  burgerIcon: {
    '&:hover': {
      backgroundColor: 'transparent ',
    },
    '& svg rect': {
      fill: theme.palette.common.white,
    },
    '& svg path': {
      stroke: theme.palette.common.white,
    },
  },
  normalBg: {
    fill: theme.palette.text.primary,
    stroke: 'none',
  },
  list: {
    position: 'absolute',
    left: 0,
    width: '100%',
    background: '#333',
    zIndex: 1000,
    padding: theme.spacing(1, 0, 0),
    marginTop: 61,
    overflowX: 'hidden',
    boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.05)',
  },
  lightLink: {
    display: 'block',
    width: '100%',
    color: '#333',
    fontSize: 16,
    fontWeight: 600,
    background: '#E8EBEC',
    padding: '8px 16px',
  },
}));

const StyledContainer = withStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    padding: theme.spacing(1.4, 2.5),
  },
}))(Container);

const StyledListItemText = withStyles(() => ({
  primary: {
    fontSize: 16,
    color: '#fff',
    fontWeight: 600,
  },
}))(ListItemText);

const MobileMenu: FC<NewNavInterface> = ({ showTransparentBg = false, subNav }) => {
  const { t } = useI18n();

  const isDark = useIsDarkTheme();
  const classes = useStyles({ isDark });
  const [open, setOpen] = useState<boolean>(false);
  const toggleMenu = () => setOpen(!open);

  const CloseIcon: FC = () => (isDark ? <NavCloseIcon /> : <NavCloseIconDark />);
  const MenuIcon: FC = () => (isDark ? <BurgerMenu /> : <BurgerMenuDark />);

  return (
    <>
      <MobileAppLink />
      <Grid className={clsx(classes.topNav, showTransparentBg && classes.newTopNavTransparent)}>
        <StyledContainer>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <PageLink href="/" aria-label={'Theme Logo'}>
                {isDark ? <img src={LogoLight} alt={'LogoLight'} /> : <DarkMobileLogo />}
              </PageLink>
            </Grid>
            <Grid className={classes.blockIcon} item>
              <IconButton
                onClick={toggleMenu}
                color="inherit"
                aria-label="menu"
                className={classes.burgerIcon}
              >
                {open ? <CloseIcon /> : <MenuIcon />}
              </IconButton>
            </Grid>
          </Grid>
          <Fade in={open}>
            <Paper>
              <List component="nav" className={classes.list}>
                <PageLink href={'/'}>
                  <ListItem button>
                    <StyledListItemText primary={t('nav.home')} />
                  </ListItem>
                </PageLink>
                <PageLink href={'/about-us'}>
                  <ListItem button>
                    <StyledListItemText primary={t('nav.about')} />
                  </ListItem>
                </PageLink>
                <NavLinkDropdownMobile links={dropdownOptions} label={t('nav.services')} />
                {/*<PageLink href={'/blog'}>
                  <ListItem button>
                    <StyledListItemText primary={t('nav.blog')} />
                  </ListItem>
                </PageLink>*/}
                {/*<PageLink href={'/our-providers'}>
                  <ListItem button>
                    <StyledListItemText primary={t('nav.providers')} />
                  </ListItem>
                </PageLink>*/}
                <PageLink href={'/contact-us'}>
                  <ListItem button>
                    <StyledListItemText primary={t('nav.contact')} />
                  </ListItem>
                </PageLink>
                <PageLink href={'/faq'}>
                  <ListItem button>
                    <StyledListItemText primary={t('nav.faq')} />
                  </ListItem>
                </PageLink>
                {subNav && <SubNavMobile links={subNav} />}
              </List>
            </Paper>
          </Fade>
        </StyledContainer>
      </Grid>
    </>
  );
};

export default MobileMenu;
